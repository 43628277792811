body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: red;
  font-family: helvetica;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: black;
}

div.Categories {
  position: center;
  width: 300px
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  font-size: 25px;
}

div.menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: baseline;
}

.display-table-row {
  border-bottom: 1px solid #cfcfcf;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(207, 207, 207);
  padding:10px;
}

.back-to-top {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  font-size: 25px;
}

.hidden {
  display: none;
}

button.roulette {
  margin: 10px;
  font-size: 18px;
  height: 38px;
  width: 100px;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
  background: rgb(141,217,252);
  cursor: pointer;
 }
